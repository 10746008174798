import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination } from "swiper";

export default function Catalogue(props) {
  return (
    <section className={`catalogue-section ${props.text}`}>
      <div className="container py-5">
        <h4 className="h2_heading text-center">IGNITE AVAILABLE SIZES</h4 >
        <Swiper
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper mt-5">
          <SwiperSlide>
            <div className="bg-image" 
            data-fancybox="gallery"
            data-src="images/01.jpg">
              <div className="text-wrapper text-start">
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-image-2"
             data-fancybox="gallery"
             data-src="images/02.jpg">
              <div className="text-wrapper text-start">
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-image-3"
             data-fancybox="gallery"
             data-src="images/03.jpg">
              <div className="text-wrapper text-start">
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-image-4"
             data-fancybox="gallery"
             data-src="images/04.jpg">
              <div className="text-wrapper text-start">
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-image-5"
             data-fancybox="gallery"
             data-src="images/05.jpg"
            >
              <div className="text-wrapper text-start">
                
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-image-6"
             data-fancybox="gallery"
             data-src="images/06.jpg">
              <div className="text-wrapper text-start">
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="d-flex justify-content-center">
                      <a href="download/mosaic/Ignite_Available_Size.pdf" target="_blank">
                        <button className="mt-4">View All</button>
                      </a>
                    </div>
      </div>
    </section>
  );
}
