import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Banner from "./banner-section";
import { Helmet } from "react-helmet";
import Footer from "./footer";
import Navbar from "./navbar";
import Loading from "./loading";

const Blogs = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <h2>Best Sintered Stone Manufacturers - Ignite Mosaic</h2>
                <link rel="canonical" href="https://ignitemosaic.com/blogs"></link>
                <meta name="description" content="Revitalize your surroundings: Elevate Your Space with Ignite Mosaic Porcelain Slab. Experience excellence with our Ignite Mosaic service." />
           
            </Helmet>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Navbar modalBg="modalbg" />
                    <main id="blogs">
                        <Banner data="Blogs" image="preview/preview_7_800x1200_9_s.jpg" />
                        <section className="section-2 padding-top">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="bg-color">
                                            <div className="row">
                                                <div className="col-lg-6 d-flex flex-column justify-content-center">
                                                    <div className="image-wrapper">
                                                        <img src="preview/mosaic.jpg" alt="masaictiles" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 ">
                                                    <small>01 Jan 2023</small>
                                                    <h2 className="h2_heading my-4">Mosaic Tiles: A Complete Guide</h2>
                                                    <p>
                                                        A mosaic tile can enhance the aesthetics of your home in many ways, from wall cladding to swimming pool flooring. Learn everything about beautiful mosaic tiles. Why do homeowners wants them in their home decor.
                                                    </p>
                                                    <Link to="/mosaic-tiles-a-complete-guide"><button className="blog-button">Read More</button></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 mt-5">
                                        <div className="bg-color">
                                            <div className="row">

                                                <div className="col-lg-6">
                                                    <small>01 Jan 2023</small>
                                                    <h2 className="h2_heading my-4">Reasons To Choose Porcelain Tiles For your Home </h2>
                                                    <p>
                                                        The market is full of different kinds of flooring materials. But that cannot defame porcelain tiles’ efficacy and remarkable benefits. Here are ten reasons to choose porcelain tiles for your floors and walls in your home.
                                                    </p>
                                                    <Link to="/reason-to-choose-porcelain-tiles-for-your-home"><button className="blog-button">Read More</button></Link>
                                                </div>
                                                <div className="col-lg-6 mt-lg-0 mt-3 d-flex flex-column justify-content-center">
                                                    <div className="image-wrapper">
                                                        <img src="previews/6.jpg" alt="porcelain" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                    <Footer />
                </>
            )}
        </>
    )
}

export default Blogs;