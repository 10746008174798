import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
// import required modules
import { Autoplay } from "swiper";

export default function Social_Icons() {
    const [show, setShow] = useState(false)
    return (
        <>
            <div id="social-icons">
                <div className="wrapper">
                  
                </div>
                <button onClick={() => setShow(!show)}>
                    <Swiper
                    autoplay={{
                        loop:true,
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                     className="mySwiper">
                         <SwiperSlide>
                         <Link to='https://api.whatsapp.com/send/?phone=919033198246&text&type=phone_number&app_absent=0'> <div className="social">
                                        <img src="/images/whatsapp.png" alt="whatsapp" />
                                        <p>Whatsapp</p>
                                    </div>
                                    </Link>
                        </SwiperSlide>
                    </Swiper>
                </button>
            </div>
        </>
    )
}