import React, { useEffect, useRef, useState } from "react";
// import Banner from "./banner-section";
import Banner from "./banner-section-media";
import { FaSearch } from "react-icons/fa";
import "@fancyapps/ui/dist/fancybox.css";
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import { Link } from "react-router-dom";
import About_Mosaic from "./about-mosaic";
import { Helmet } from "react-helmet";
import Navbar from "./navbar";
import Footer from "./footer";
import Loading from "./loading";

const Media = () => {

    const [opacity, setOpacity] = useState(1);
    const [opacity2, setOpacity2] = useState(1);
    const [opacity3, setOpacity3] = useState(1);
    const [opacity4, setOpacity4] = useState(1);
    const [loading, setLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);
    const videoRef3 = useRef(null)
    const videoRef4 = useRef(null);

    function handlePlayPause(videoRef) {
        const video = videoRef.current;

        if (!video) {
            return;
        }

        if (isPlaying) {
            video.pause();
            setIsPlaying(false);
        } else {
            video.play();
            setIsPlaying(true);
        }
    }
    function handlePause1() {
        setOpacity(1);
        setIsPlaying(false)
    }
    function handlePlay1() {
        setOpacity(0);
        setIsPlaying(true)
    }
    function handlePause2() {
        setOpacity2(1);
        setIsPlaying(false)
    }
    function handlePlay2() {
        setOpacity2(0);
        setIsPlaying(true)
    }
    function handlePause3() {
        setOpacity3(1);
        setIsPlaying(false)
    }
    function handlePlay3() {
        setOpacity3(0);
        setIsPlaying(true)
    }
    function handlePause4() {
        setOpacity4(1);
        setIsPlaying(false)
    }
    function handlePlay4() {
        setOpacity4(0);
        setIsPlaying(true)
    }
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Best Mosaic Tiles & Porcelain Tiles Manufacturer - Ignite Mosaic</title>
                <meta name="description" content="Discover excellence in sintered stone with Ignite Mosaic, India's premier manufacturer. Elevate your spaces with our superior Products." />
                <link rel="canonical" href="https://ignitemosaic.com/media"></link>
            </Helmet>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Navbar modalBg="modalbg" />
                    <main id="media">
                        <Banner data="Media" image="preview/preview_6_800x1200_9_s.jpg" />
                        <About_Mosaic />
                        <div className="container padding-top">
                        <h2 className="h2_heading text-center">Customize Size Factory And Mosaic Factory Videos</h2>
                        <br />
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="video-wrapper">
                                        <div className="video-container" id="video-container">
                                            <video
                                                ref={videoRef1}
                                                controls id="video" preload="metadata"
                                                onPlay={handlePlay1}
                                                onPause={handlePause1}>
                                                <source src="/videos/01.mp4" type="video/mp4" />
                                            </video>
                                            <div className="play-button-wrapper">
                                                <div title="Play video" className="play-gif" id="circle-play-b" style={{ "opacity": `${opacity}` }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" onClick={()=>handlePlayPause(videoRef1)}>
                                                        <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-md-6 mt-md-0 mt-4">
                                    <div className="video-wrapper"> 
                                        <div className="video-container" id="video-container">
                                            <video
                                                ref={videoRef2}
                                                controls id="video" preload="metadata"
                                                onPlay={handlePlay2}
                                                onPause={handlePause2}>
                                                <source src="/videos/02.mp4" type="video/mp4" />
                                            </video>
                                            <div className="play-button-wrapper">
                                                <div title="Play video" className="play-gif" id="circle-play-b" style={{ "opacity": `${opacity2}` }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" onClick={()=>handlePlayPause(videoRef2)}>
                                                        <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 mt-lg-0 mt-4">
                                    <div className="video-wrapper">
                                        <div className="video-container" id="video-container">
                                            <video
                                                ref={videoRef3}
                                                controls id="video" preload="metadata"
                                                onPlay={handlePlay3}
                                                onPause={handlePause3}>
                                                <source src="/videos/03.mp4" type="video/mp4" />
                                            </video>
                                            <div className="play-button-wrapper">
                                                <div title="Play video" className="play-gif" id="circle-play-b" style={{ "opacity": `${opacity3}` }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" onClick={()=>handlePlayPause(videoRef3)}>
                                                        <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 mt-lg-0 mt-4">
                                    <div className="video-wrapper">
                                        <div className="video-container" id="video-container">
                                            <video
                                                ref={videoRef4}
                                                controls id="video" preload="metadata"
                                                onPlay={handlePlay4}
                                                onPause={handlePause4}>
                                                <source src="/videos/04.mp4" type="video/mp4" />
                                            </video>
                                            <div className="play-button-wrapper">
                                                <div title="Play video" className="play-gif" id="circle-play-b" style={{ "opacity": `${opacity4}` }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" onClick={()=>handlePlayPause(videoRef4)}>
                                                        <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </>
            )}
        </>
    )
}

export default Media;


