import React, { useEffect, useState } from "react";
import {
  FaHandshake,
  FaUsers,
  FaUserEdit,
  FaDraftingCompass,
  FaNetworkWired,
  FaStreetView,
} from "react-icons/fa";
import Catalogue from "./catalogue";
import Latest_Insights from "./get-latest-insights";
import Slider from "./slack-slider";
import { Helmet } from "react-helmet";
import Navbar from "./navbar";
import Footer from "./footer";
import Loading from "./loading";

const Company = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Porcelain & Mosaic tiles manufacturer in India - Ignite Mosaic</title>
        <meta
          name="description"
          content="Ignite Mosaic: Leading Glass Mosaic Tile Manufacturer from India, crafting vibrant and exquisite mosaic designs." />
        <link rel="canonical" href="https://ignitemosaic.com/company"></link>
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar modalBg="modalbg" />
          
          <main id="company">
            <section className="section-1">
              <div className="bg">
                <div className="text-wrapper">
                  <h1>
                    Delivering <br /> Captivating Tiles For <br /> A Mesmerising
                    Experience.
                  </h1>
                </div>
              </div>
            </section>
            <section className="section-2">
              <div className="row gx-0">
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="text-wrapper">
                    <h2 className="h2_heading">
                      ABOUT IGNITE<sup>&#174;</sup>
                    </h2>
                    <p className="my-4">
                      In 2011, we started our journey as a <b>Ceramic Zone<sup>&#174;</sup></b> tile trader in India.
                      Since 2016, Ignite has been exporting
                      internationally.
                    </p>
                    <p>
                      Ignite Porcelain Tiles started manufacturing in 2017 with
                      a dream to beautify spaces and places. With over a decade
                      of experience in the tile world, we serve customers with
                      affordable yet stylish and innovative tiles for their
                      homes, offices, and other premises.
                    </p>
                    <p>
                      We have carved our niche and are renowned as India’s best
                      tile manufacturer and exporter. Producing and exporting
                      high-quality{" "}
                      <b>
                        Outdoor, Porcelain Tiles, Porcelain Slab, Mosaic,
                        Sintered stone And Ceramic Floor & Wall Tiles
                      </b>
                      , Ignite aims to lend the perfect charm of aesthetics and
                      functionality with supreme-quality products.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <img src="preview/preview_2_800x1200_9_s.jpg" alt="ignitepreview" />
                </div>
              </div>
            </section>
            <section className="section-3 bg-color">
              <div className="container">
                <h2 className="h2_heading text-center">VALUES WE ADHERE</h2>
                <p className="text-center mt-3">
                  With great vision, innovative ideas, and advanced technology,
                  <b> Ignite<sup>&#174;</sup> </b> aims to achieve new heights and become a
                  prominent global tile exporter. Our values guide us to
                  constantly increase the benchmark of our performance; they
                  define our passion and commitment towards our brand,
                  customers, and employees.
                </p>
                <div className="row mt-5 justify-content-center">
                  <div className="col-lg-2 col-md-4 col-6 d-flex flex-column justify-content-center align-items-center">
                    <FaUsers className="icon" />
                    <p>Transperancy</p>
                  </div>
                  <div className="col-lg-2 col-md-4 col-6 d-flex flex-column justify-content-center align-items-center">
                    <FaHandshake className="icon" />
                    <p>Quality Assurance</p>
                  </div>
                  <div className="col-lg-2 col-md-4 col-6 d-flex flex-column justify-content-center align-items-center">
                    <FaNetworkWired className="icon" />
                    <p>Innovative designs</p>
                  </div>
                  <div className="col-lg-2 col-md-4 col-6 d-flex flex-column justify-content-center align-items-center">
                    <FaUserEdit className="icon" />
                    <p>Customer-centric</p>
                  </div>
                  <div className="col-lg-2 col-md-4 col-6 d-flex flex-column justify-content-center align-items-center">
                    <FaStreetView className="icon" />
                    <p>Eco-friendly</p>
                  </div>
                  <div className="col-lg-2 col-md-4 col-6 d-flex flex-column justify-content-center align-items-center">
                    <FaDraftingCompass className="icon" />
                    <p>Aesthetic value</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-4">
              <div className="row gx-0">
                <div className="col-lg-6">
                  <img src="preview/preview_5_800x1200_9_s.jpg" alt="mission" />
                </div>
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="text-wrapper">
                    <h2 className="h2_heading">MISSION </h2>
                    <p className="mt-4">
                      We aim for excellence in everything we do.
                    </p>
                    <p>
                      One of our main objectives is customer satisfaction with
                      the best service available.
                    </p>
                    <p>
                      To manufacture and supply the highest quality products at
                      competitive prices.
                    </p>
                    <p>
                      <b>Ignite<sup>&#174;</sup> </b> never compromises with quality. We aim to deliver
                      only the best quality tiles through innovation, R&D, and
                      new concepts.
                    </p>
                    <h2 className="h2_heading mt-4">VISION </h2>
                    <p className="mt-4">
                      Our ultimate goal is to achieve worldwide recognition as a
                      porcelain tile manufacturer & exporter with our innovative
                      and quality products.
                    </p>
                    <p>
                      With innovation as our forte, we envision creating
                      excellent quality tiles and becoming the most trusted tile
                      brand.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-5 padding-top">
              <div className="container">
                <div className="row ">
                  <div className="col-lg-6">
                    <h2 className="h2_heading">
                      WHY CHOOSE  IGNITE<sup>&#174;</sup> ?
                    </h2>
                    <p style={{ fontSize: "20px" }}>
                      We are dedicated to creating benchmarks that will measure
                      future achievements and results. These are some of the
                      reasons why Ignite is the right tile brand for your
                      every project.
                    </p>
                    <h6>Modernized technology</h6>
                    <p>
                      A modernized & well-infrastructure unit supports our team
                      in achieving qualitative tile products with an assured
                      life span.
                    </p>
                    <h6>Top Quality Products</h6>
                    <p>
                      Quality analysis is a very pertinent practice at Ignite as
                      we abide by strict production specifications. We strive to
                      win the trust of customers worldwide by maintaining
                      consistency in new innovative designs & quality.
                    </p>
                    <h6>Wide Range of Products</h6>
                    <p>
                      We believe in continuous innovation of robust, flexible,
                      and customizable production resulting in a wide range of
                      production of aesthetic designs and excellent quality.
                    </p>
                    <h6>Wide export coverage</h6>
                    <p>
                      Since 2016, we have expanded our reach as a national &
                      international tile exporter with a strong presence in over
                      30+ countries with thousands of satisfied customers.
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <img src="preview/DYNA VENATO_001.jpg" alt="DYNA VENATO" width="800px" height="600" />
                  </div>
                </div>
              </div>
            </section>
            <Slider />
            <section className="section-7 padding-top">
              <div className="bg-color">
                <Catalogue text="py-4" />
              </div>
            </section>
            <Latest_Insights />
          </main>
          <Footer />
        </>
      )}
    </>
  );
};

export default Company;
