import React, { useEffect } from "react";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha
} from "react-simple-captcha";

const CaptchaTest = () =>{
  useEffect(()=>{
    loadCaptchaEnginge(8);
  },[])
    return (
      <div>
        <div className="container">
          <div className="form-group">
            <div className="col mt-3">
              <LoadCanvasTemplate />
            </div>
          </div>
        </div>
      </div>
    );
}

export default CaptchaTest;
