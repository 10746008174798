import React, { useEffect, useState } from "react";
// import Banner from "./banner-section";
import Banner from "./banner-section-contact";
import { Helmet } from "react-helmet";
import Navbar from "./navbar";
import Footer from "./footer";
import Loading from "./loading";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha
} from "react-simple-captcha";
import CaptchaTest from "./captchaTest";
import { send } from 'emailjs-com';

const Contact = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [captchaErr, setCaptchaErr] = useState(true);

  const [emailData, setEmailData] = useState({
    your_name: '',
    email: '',
    number:'',
    message: ''
  });
  const iframeStyle = {
    border: '1px solid #f05a28',
  };
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [captcha1, setCaptcha1] = useState('');
  const [answer1, setAnswer1] = useState('');


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    loadCaptchaEnginge(8);
  }, []);


  const validate = () => {
    let nameError = '';
    let phoneError = '';
    let emailError = '';

    if (!emailData.your_name) {
      nameError = 'Name is required';
    }

    if (!emailData.number) {
      phoneError = 'Phone number is required';
    } else if (!/^[0-9\b]+$/.test(emailData.number)) {
      phoneError = 'Invalid phone number';
    }

    if (!emailData.email) {
      emailError = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(emailData.email)) {
      emailError = 'Invalid email address';
    }

    setNameError(nameError);
    setPhoneError(phoneError);
    setEmailError(emailError);

    return !nameError && !phoneError && !emailError;
  };

  const onSubmit = e => {
    e.preventDefault()
    const isValid = validate();
    if (isValid) {
      let user_captcha = document.getElementById("user_captcha_input").value;

      if (validateCaptcha(user_captcha) == true) {
        // alert("Captcha Matched");
        setCaptchaErr(true)
        loadCaptchaEnginge(6);
        fetch("http://localhost:3000/send",{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                name:emailData.your_name,
                email:emailData.email,
                mobile:emailData.number,
                message: emailData.message
            })
        }).then(res=>res.json())
        .then(data=>{
            
            navigate('/thankyou');
        }).catch(err=>{
            console.log(err)
        });

        document.getElementById("user_captcha_input").value = "";
      } else {
        // alert("Captcha Does Not Match");
        setCaptchaErr(false)
        document.getElementById("user_captcha_input").value = "";
      }
    }
  };
  
  const handleChange = (e) => {
    setEmailData({
      ...emailData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact</title>
        <meta
          name="description"
          content="Looking for a reliable tile brand for your project? Get in touch with Ignite Mosaic, the best tile manufacturer & exporter in  India, for your project. Contact us for a quick inquiry by completing the form. We'll get in touch soon!"
        />
        <link rel="canonical" href="https://ignitemosaic.com/contact"></link>
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar modalBg="modalbg" />
          <main id="contact">
            <Banner
              data="Contact Us"
              image="preview/preview_5_800x1200_9_s.jpg"
            />
            <section className="section-2 padding-top">
              <div className="container">
                <h2 className="h2_heading text-center">
                We would love to work with you   </h2>
                <div className="row my-5">
                  <div className="col-md-4">
                    <div className="d-flex flex-column justify-content-center align-items-center text-center icon-bg">
                      <FaMapMarkerAlt className="icon" />
                      <h6 className="mt-4">Address</h6>
                      <p>
                        Ceramic Zone Complex, Nr Metro Ceramic,
                        Lakhdhirpur Road, Morbi (guj.) India - 363 642
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column justify-content-center align-items-center text-center icon-bg">
                      <FaEnvelope className="icon" />
                      <h6 className="mt-4">Email</h6>
                      <p>
                        <a href="mailto:export@ceramiczone.co.in">
                          export@ceramiczone.co.in
                        </a>
                      </p>

                      <p>
                        <a href="mailto:sales@ignitemosaic.com">
                          sales@ignitemosaic.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex flex-column justify-content-center align-items-center text-center icon-bg">
                      <FaPhoneAlt className="icon" />
                      <h6 className="mt-4">Phone Number</h6>
                      <p>+91 90331 98246 / +91 99786 24022</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <form onSubmit={onSubmit}>
                      <div className="row mt-5">
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              className="form-control"
                              name="your_name"
                              type="text"
                              id="floatingInput"
                              placeholder="input"
                              onChange={handleChange} 
                              value={emailData.your_name}
                            />
                            <label htmlFor="floatingInput">Your Name</label>
                            <div style={{ color: 'red' }}>{nameError}</div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-md-0 mt-4">
                          <div className="form-floating">
                            <input
                              className="form-control"
                              name="email"
                              type="email"
                              id="floatingInput"
                              placeholder="email"
                              onChange={handleChange} value={emailData.email}
                            />
                            <label htmlFor="floatingInput">Email</label>
                            <div style={{ color: 'red' }}>{emailError}</div>
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="form-floating">
                            <input
                              className="form-control"
                              name="number"
                              type="tel"
                              id="floatingInput"
                              placeholder="tel"
                              onChange={handleChange} value={emailData.number}
                            />
                            <label htmlFor="floatingInput">Phone Number</label>
                            <div style={{ color: 'red' }}>{phoneError}</div>
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="form-floating">
                            <textarea
                              name="message"
                              className="form-control"
                              id="floatingTextarea"
                              placeholder="Leave a Message here"
                              onChange={handleChange} value={emailData.message}
                            ></textarea>
                            <label htmlFor="floatingTextarea">Message</label>
                          </div>
                        </div>

                        <CaptchaTest />
                        
                        <div className="col-12 mt-4">
                          <div className="">
                            <input
                              className="form-control"
                              placeholder="Enter Captcha"
                              id="user_captcha_input"
                              name="user_captcha_input"
                              type="text"
                            ></input>
                            {!captchaErr && (
                              <p>Captcha does Not match</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 text-center">
                        <button type="submit" >Submit</button>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6 mt-lg-0 mt-3">
                    <div className="wrapper">
                    </div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14710.133656055905!2d70.8783415!3d22.8197466!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39598d45e0d2570b%3A0x1cdf8e9bb60fe922!2sIgnite%20mosaic!5e0!3m2!1sen!2sin!4v1679910602678!5m2!1sen!2sin"
                      width="100%"
                      height="100%"
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade" 
                      style={iframeStyle}
                    ></iframe>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      )}
    </>
  );
};

export default Contact;
