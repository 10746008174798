import { useEffect, useState } from "react";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Banner from "./banner-section-products";
import Footer from "./footer";
import Latest_Insights from "./get-latest-insights";
import Loading from "./loading";
import Navbar from "./navbar";
import { send } from 'emailjs-com';
import jquery from 'jquery';
import '@fortawesome/fontawesome-free/css/all.css';
// import Gallery from './Gallery';


export default function Download() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  // Mosaic Data
  const mosaic =
    [
      {
        "name": "Polished Glazed Porcelain Mosaic",
        "url": "download/mosaic/mosaic.jpg",
        "pdf": "/download/mosaic/Polished_GlazedPorcelain_Mosaic.pdf"
      },
      {
        "name": "Glazed Porcelain Mosaic",
        "url": "download/mosaic/mosaic.jpg",
        "pdf": "/download/mosaic/Glazed_Porcelain_Mosaic.pdf"
      },
      {
        "name": "Unglazed Porcelain Mosaic",
        "url": "download/mosaic/mosaic.jpg",
        "pdf": "/download/mosaic/Unglazed_PorcelainMosaic.pdf"
      },
    ]

  // porcelainTiles data

  const porcelainTiles =
    [
      {
        "name": "Fullbody Unglazed Porcelain Tiles - 1",
        "url": "download/Porcelain-tiles/Fullbody-Unglazed_PorcelainTiles_60x120cm_01.png",
        "pdf": "/download/Porcelain-tiles/Fullbody-Unglazed_PorcelainTiles_60x120cm_01.pdf"
      },
      {
        "name": "Fullbody Unglazed Porcelain Tiles - 2",
        "url": "download/Porcelain-tiles/Fullbody-Unglazed_PorcelainTiles_60x120cm_02.png",
        "pdf": "/download/Porcelain-tiles/Fullbody-Unglazed_PorcelainTiles_60x120cm_02.pdf"
      },
      {
        "name": "Pastel Colour",
        "url": "download/Porcelain-tiles/Pastel_Colour_60x120cm.png",
        "pdf": "/download/Porcelain-tiles/Pastel_Colour_60x120cm.pdf"
      },
      {
        "name": "3D",
        "url": "download/Porcelain-tiles/3D60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/3D60x120cm.pdf"
      },
      {
        "name": "Bookmatch",
        "url": "download/Porcelain-tiles/BOOKMATCH-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/BOOKMATCH-60x120cm.pdf"
      },
      {
        "name": "Endmatch",
        "url": "download/Porcelain-tiles/ENDMATCH-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/ENDMATCH-60x120cm.pdf"
      },
      {
        "name": "Glossy",
        "url": "download/Porcelain-tiles/GLOSSY-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/GLOSSY-60x120cm.pdf"
      },
      {
        "name": "High Glossy",
        "url": "download/Porcelain-tiles/HIGH-GLOSSY-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/HIGH-GLOSSY-60x120cm.pdf"
      },
      {
        "name": "Matt Carving",
        "url": "download/Porcelain-tiles/MATT-CARVING-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/MATT-CARVING-60x120cm.pdf"
      },
      {
        "name": "PGVT",
        "url": "download/Porcelain-tiles/POLISHED-GLAZED-VITRIFID-TILE-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/POLISHED-GLAZED-VITRIFID-TILE-60x120cm.pdf"
      },
      {
        "name": "Rustic",
        "url": "download/Porcelain-tiles/RUSTIC-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/RUSTIC-60x120cm.pdf"
      },
      {
        "name": "Satvario",
        "url": "download/Porcelain-tiles/SATVARIO-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/SATVARIO-60x120cm.pdf"
      },
      {
        "name": "Wooden",
        "url": "download/Porcelain-tiles/WOODEN-60x120cm.jpg",
        "pdf": "/download/Porcelain-tiles/WOODEN-60x120cm.pdf"
      },
    ]

  // Porcelain Slad
  const porcelainSlad =
    [
      {
        "name": "800x1600 MM",
        "url": "download/Porcelain-Slab/800x1600mm-9mmSlab.jpg",
        "pdf": "/download/Porcelain-Slab/800x1600mm-9mmSlab.pdf"
      },
      {
        "name": "1200x2400 MM",
        "url": "download/Porcelain-Slab/1200x2400mm-9mm-Slab.jpg",
        "pdf": "/download/Porcelain-Slab/1200x2400mm-9mm-Slab.pdf"
      },
      {
        "name": "1200x1200 MM",
        "url": "download/Porcelain-Slab/1200X1200mm-9mm-Slab.jpg",
        "pdf": "/download/Porcelain-Slab/1200X1200mm-9mm-Slab.pdf"
      },
      {
        "name": "1800x1200 MM",
        "url": "download/Porcelain-Slab/1800X1200mm-9mm-Slab.jpg",
        "pdf": "/download/Porcelain-Slab/1800X1200mm-9mm-Slab.pdf"
      },

      {
        "name": "1600x3200 MM",
        "url": "download/Porcelain-Slab/1600x3200.jpg",
        "pdf": "/download/Porcelain-Slab/1600x3200.pdf"
      },
    ]

  // Outdoor
  const outdoor =
    [
      {
        "name": "Outdoor",
        "url": "download/Outdoor-20mm/Outdoor-20mm.jpg",
        "pdf": "/download/Outdoor-20mm/Outdoor-20mm.pdf"
      },
      {
        "name": "600x600 MM",
        "url": "download/Outdoor-20mm/600x600-outdoor.jpg",
        "pdf": "/download/Outdoor-20mm/600x600-outdoor.pdf"
      },
    ]

  // Sintered Stone
  const sintered =
    [
      {
        "name": "800x2400 MM",
        "url": "download/Sintered-Stone/800x2400mm-15mm-Sintered_Stone.jpg",
        "pdf": "/download/Sintered-Stone/800x2400mm-15mm-Sintered_Stone.pdf"
      },
      {
        "name": "1200x1600 MM",
        "url": "download/Sintered-Stone/1200-X-1600mm-15mm-Sintered-Stone.jpg",
        "pdf": "/download/Sintered-Stone/1200-X-1600mm-15mm-Sintered-Stone.pdf"
      },
    ]


  const [formData, setFormData] = useState({
    product_name: '',
    your_name: '',
    comp_name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    contact: '',
    email: ''
  });
  const [modalData, setModalData] = useState(null);
  const [productName, setProductName] = useState(null);
  const handleButtonClick = (product) => {
    setModalData(product.pdf);
    setProductName(product.name)
  };
  const onSubmit = (event, props) => {
    event.preventDefault();
    fetch("http://localhost:3000/product-data", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        your_name: formData.your_name,
        product_name: productName,

        comp_name: formData.comp_name,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        contact: formData.contact,
        from_email: formData.email,
      })
    }).then(res => res.json())
      .then(data => {

        window.open(modalData, '_self');
      }).catch(err => {
        console.log(err)
      });
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const imageUrls = [

    'ceramicapreview/1.jpg',
    'ceramicapreview/2.jpg',
    'ceramicapreview/3.jpg',
    'ceramicapreview/4.jpg',
    // 'ceramicapreview/5.jpg',
    // 'ceramicapreview/6.jpg',
    // 'ceramicapreview/7.jpg',
    // 'ceramicapreview/8.jpg',
    // 'ceramicapreview/9.jpg',
    // 'ceramicapreview/10.jpg',
    // 'ceramicapreview/11.jpg',
    // 'ceramicapreview/12.jpg',
    // 'ceramicapreview/13.jpg',
    // 'ceramicapreview/14.jpg',
    // 'ceramicapreview/15.jpg',
    // 'ceramicapreview/16.jpg',
    // 'ceramicapreview/17.jpg',
    // 'ceramicapreview/18.jpg',
    // 'ceramicapreview/19.jpg',
    // 'ceramicapreview/20.jpg',
    // 'ceramicapreview/21.jpg',
    // 'ceramicapreview/22.jpg',
    // 'ceramicapreview/23.jpg',
    // 'ceramicapreview/24.jpg',
    // 'ceramicapreview/25.jpg',
    // 'ceramicapreview/26.jpg',
    // 'ceramicapreview/27.jpg',
    // 'ceramicapreview/28.jpg',
    // 'ceramicapreview/29.jpg',
  ];
  const [image1, image2, image3] = imageUrls;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sintered Stone & Ceramic Wall Tiles collection - Ignite Mosaic</title>
        <meta
          name="description"
          content="Leading porcelain tiles dealers in India, Ignite Mosaic offers premium quality and stylish options for a touch of sophistication in every space"
        />
        <link rel="canonical" href="https://ignitemosaic.com/products"></link>
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Navbar modalBg="modalbg" />
          <main id="download">
            <Banner
              data="Products"
              image="previews/9.jpg"
              />
              {/* <h4>Floor Tile Des  ign: Elevating Your Experience</h4> */}

            <section className="padding-top">
              <div className="container">
                {/* 1 */}
                <div className="row mpt-50 spb-80 new-display-other" id="glass-mosaics">

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                      <img src="/preview/GLASS-MOSAICS-TILES.jpg" alt="GLASS-MOSAICS-TILES" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>GLASS MOSAICS TILES</h2>
                      <h6>Premium Glass Mosaic Tiles for Stunning Interior Designs.</h6>
                      <h6>Glass mosaic tiles are a popular choice for various design applications due to their luxurious finish and versatility. They come in a wide range of colours, shapes, and sizes.</h6>

                      <p>1) Material: As the name suggests, these tiles are made primarily of glass.The glass can be clear or coloured, and it may have various finishes such as glossy, matte, or iridescent.</p>
                      <p>2) Variety: Glass mosaic tiles come in various shapes, such as subway tiles, traditional mirror look, and unique shapes like fish scale tile and herringbone tile.</p>
                      <p>3) Easy to clean: Glass mosaic tiles can be easily cleaned with soap and water.</p>
                      <p>4) Bathroom and pool: Glass mosaic tiles are suitable for bathroom walls, shower floors, and pools, providing a stylish and durable option for these areas</p>

                    </div>
                  </div>
                </div>

                {/* 2 */}
                <div className="row mpt-50 spb-80 new-display-other" id="swimming">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>Swimming Pool Mosaic Tiles</h2>
                      <h6>Premium Glass Mosaic Tiles for Stunning Interior Designs.</h6>
                      <h6>When it comes to swimming pool mosaics, you're dealing with a specific type of glass tile suited for underwater environments.</h6>

                      <p>1) Material: Pool mosaic tiles are typically made of durable materials such as glass, porcelain, or ceramic.These materials are chosen for their resistance to water, chemicals, and fading due to sunlight.</p>
                      <p>2) Size and Shape: Pool mosaic tiles come in various sizes and shapes. Common shapes include square, rectangular, hexagonal, and fish scale, among others. The size and shape can influence the overall appearance of the pool.</p>
                      <p>3) Maintenance: Pool mosaic tiles are generally easy to clean and maintain. Regular cleaning with a pool brush or a non-abrasive cleaner can help keep them looking fresh.</p>
                      <p>4) Custom features: Mosaics can be used to personalise areas like fountains, waterfalls, or swim-up bars</p>

                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/SWIMMING-POOL-TILES.jpg" alt="SWIMMING-POOL-TILES" />
                    </div>
                  </div>
                </div>

                {/* 3 */}
                <div className="row mpt-50 spb-80 new-display-other" id="vitrified">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/VITRIFIED-MOSAIC-TILES.jpg" alt="VITRIFIED-MOSAIC-TILES" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12" >
                    <div className="text_div">
                      <h2>Vitrified Mosaic Tiles</h2>
                      <h6>Vibrant Vitrified Mosaic Tiles: Elevate Your Space with Style and Durability.</h6>
                      <h6>Vitrified mosaic tiles are made from a mixture of clay, silica, feldspar, and other minerals.The vitrification process involves firing the tiles at high temperatures, which causes the particles to melt and fuse together, forming a solid, glass-like structure.</h6>

                      <p>1) Size Options: Vitrified tiles are available in various sizes, including mosaic formats.This provides flexibility in design, allowing for the creation of intricate  patterns or the use of smaller tiles for specific applications.</p>
                      <p>2) Water Resistance: Due to their low porosity, vitrified mosaic tiles are highly water-resistant. including bathrooms, kitchens, and outdoor areas.</p>
                      <p>3) Durability: Vitrified tiles are known for their exceptional durability.They are resistant to scratches, abrasion, and impact, making them suitable for high-traffic areas.</p>
                    </div>
                  </div>
                </div>

                {/* 4 */}
                <div className="row mpt-50 spb-80 new-display-other" id="purv_series">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>Purv Series Sintered Stones</h2>
                      <h6>Purv Series Sintered Stones: Timeless Elegance and Unmatched Durability.</h6>
                      <h6>Absolutely! The Purv Series Sintered Stones are a collection of stunning and versatile surfaces, perfect for elevating any residential or commercial space.</h6>

                      <p>1) Sintered stones are engineered surfaces made from natural minerals, pigments, and binders that are compacted and heated to high temperatures. They are known for their durability, resistance to scratches and stains, and versatile applications in construction and design.</p>
                      <p>2) Colour : such as Ambrato Nero, Crystal Black, Dark Grey, Galaxy Ivory, Galaxy White, Ivory, Kota, Portoro Gold, S N P Verde, Saint Laurent, Statuario, Volakas, and   Walnut.</p>
                      <p>3) size : The available sizes are 800x2400mm and 800x3000mm, with glossy or matte finishes.</p>
                      <p>4) suitable for : Sintered stone is a great choice for countertops, floors, walls, and backsplashes. It is also a popular choice for outdoor applications, such as patios   and pool decks.</p>

                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/PURV-SERIES.jpg" alt="PURV-SERIES" />
                    </div>
                  </div>
                </div>

                {/* 5 */}
                <div className="row mpt-50 spb-80 new-display-other" id="ultima_series">

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/ULTIMA-SERIES.jpg" alt="ULTIMA-SERIES" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>Ultima Series Sintered Stones</h2>
                      <h6>Ultima Series Sintered Stones: Unparalleled Beauty and Strength for Modern Living Spaces.</h6>
                      <h6>The Ultima Series is a premium line of sintered stones meticulously engineered to offer unparalleled durability, aesthetic appeal, and versatility in architectural  applications.These sintered stones are created through an advanced manufacturing process that combines natural minerals, pigments, and cutting-edge technology.</h6>

                      <p>1) Durability: The Ultima Series is known for its exceptional durability, making it resistant to scratches, impact, and the effects of daily wear and tear.</p>
                      <p>2) Resistance:High resistance to scratching, staining, and chemicals: They can handle everyday wear and tear with ease, making them ideal for busy kitchens and high - traffic areas.</p>
                      <p>3) Easy to clean and maintain: Forget about harsh chemicals and laborious scrubbing. Ultima stones require minimal maintenance and wipe clean effortlessly.</p>
                    </div>
                  </div>
                </div>

                {/* 6 */}
                <div className="row mpt-50 spb-80 new-display-other" id="somu_series">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>Somu Series Slab Tiles</h2>
                      <h6>Somu Series Slab Tiles: Elevate Your Spaces with Distinctive Style and Quality Craftsmanship.</h6>
                      <h6>The Somu Series represents a premium collection of slab tiles meticulously crafted to bring sophistication and versatility to a range of architectural and design projects.</h6>

                      <p>1) Material Composition: Crafted from high-quality materials, the Somu Series slab tiles offer a combination of durability and visual elegance.</p>
                      <p>2) Colour Palette: The Somu Series offers a curated colour palette that encompasses timeless classics and contemporary trends, providing options for a wide range of design aesthetics.</p>
                      <p>3) Sizes: Choose from a variety of sizes to suit specific design requirements.</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/SOMU-SERIES.jpg" alt="SOMU-SERIES" />
                    </div>
                  </div>
                </div>

                {/* 7 */}
                <div className="row mpt-50 spb-80 new-display-other" id="roman-tiles">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/ROMAN-TILES.jpg" alt="ROMAN-TILES" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>Roman Full Body Tiles</h2>
                      <h6>Timeless Elegance Unveiled: The Roman Full Body Tiles Collection.</h6>
                      <h6>Roman Full Body Tiles is  a type of ceramic or porcelain tile where the colour and design run through the entire thickness of the tile.</h6>

                      <p>1) Durability: Roman Full Body Tiles are known for their durability and resistance to scratches and chips. Even if the tile is damaged, the colour and pattern will   remain visible, minimising the visual impact of any wear.</p>
                      <p>2) Low Maintenance: Due to their durability and consistent colouring, Roman Full Body Tiles are generally low-maintenance and easy to clean.</p>
                      <p>3) Outdoor Use: Some varieties of full-body tiles are suitable for outdoor use. They can withstand exposure to the elements, making them suitable for patios, terraces,   and other exterior applications.</p>
                      <p>4) Industrial:They are also resistant to most acids and chemicals, making them a great choice for industrial applications.These tiles are suitable for various   applications, including kitchen countertops, flooring, and wall cladding. </p>
                    </div>
                  </div>
                </div>

                {/* 8 */}
                <div className="row mpt-50 spb-80 new-display-other" id="rare-uniqe">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>Rare Unique Full Body Tiles</h2>
                      <h6>Discover Unrivalled Elegance with Rare Unique Full Body Tiles.</h6>
                      <h6>Unveiling the Enthralling World of Rare and Unique Full Body Tiles.Step into a realm where ordinary tiles fade away, and captivating artistry takes centre stage.Rare and unique full body tiles are not just floor or wall coverings.</h6>

                      <p>1) Material: Premium quality ceramic or porcelain.</p>
                      <p>2) Thickness Options: Available in different thicknesses for various applications.</p>
                      <p>3) Sizes: Choose from a variety of sizes to achieve the desired design.</p>
                      <p>4) Finishes: Options may include polished, matte, textured, or other unique finishes.</p>

                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/RARE-&-UNIQUE-TILES.jpg" alt="RARE-&-UNIQUE-TILES" />
                    </div>
                  </div>
                </div>

                {/* 9 */}
                <div className="row mpt-50 spb-80 new-display-other" id="hastag_series">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/HASHTAG-SERIES.jpg" alt="HASHTAG-SERIES" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12" >
                    <div className="text_div">
                      <h2>Hashtag Series Slab Tiles</h2>
                      <h6>Elevate Your Space with Unrivalled Elegance - Introducing the Hashtag Series Slab Tiles.</h6>
                      <h6>If "Hashtag Series" is a specific product line of slab tiles,In a general sense, slab tiles, also known as large-format tiles, are used for various applications in both residential and commercial settings.</h6>

                      <p>1) Flooring: Slab tiles are suitable for flooring in large spaces, offering a contemporary and seamless appearance.</p>
                      <p>2) Outdoor Spaces: Some slab tiles are designed for outdoor use, making them suitable for patios, decks, or other exterior applications.</p>
                      <p>3) Material: Depending on the material of the Hashtag Series tiles (porcelain, ceramic, sintered stone, etc).</p>
                      {/* <p>Finishes: Options may include polished, matte, textured, or other unique finishes.</p> */}

                    </div>
                  </div>
                </div>

                {/* 10 */}
                <div className="row mpt-50 spb-80 new-display-other" id="ceramic-floor">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>CERAMIC FLOOR TILES</h2>
                      <h6>Prime Tiles: Elevate Your Space with Ceramic Flooring Excellence</h6>
                      <h6>Ceramic floor tiles are a popular choice for flooring material in both residential and commercial structures due to their durability, versatility, and variety. Here are some key points about ceramic floor tiles</h6>

                      <p>1) Durable: Ceramic floor tiles are very hard and resistant to scratches, chips, and cracks. They can last for many years with proper care.</p>
                      <p>2) Versatility and Design: They come in a wide range of colours, designs, and finishes, making them suitable for any room in the house, including kitchens, bathrooms, living areas, and bedrooms</p>
                      <p>3) Hygienic: The non-porous surface of ceramic tiles prevents the growth of mold, mildew, and bacteria, promoting a cleaner and healthier indoor environment.</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/CERAMIC-FLOOR-TILES.jpg" alt="CERAMIC-FLOOR-TILES" />
                    </div>
                  </div>
                </div>

                {/* 11 */}
                <div className="row mpt-50 spb-80 new-display-other" id="ceramic-wall">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/CERAMIC-WALL-TILES.jpg" alt="CERAMIC-WALL-TILES" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>CERAMIC WALL TILES</h2>
                      <h6>Signature Walls: Enhance Your Space with Ceramic Wall Tiles</h6>
                      <h6>Ceramic wall tiles are a versatile and popular choice for enhancing interior spaces with style, functionality, and durability. Crafted from natural clay and minerals, ceramic wall tiles undergo a meticulous manufacturing process to create tiles that are suitable for a wide range of residential and commercial applications</h6>

                      <p>1) Material and Manufacturing: Ceramic wall tiles are made of clay, silica, and dyes, and undergo heating and cooling processes to create different products with varying characteristics</p>
                      <p>2) Durable: Ceramic wall tiles are very hard and resistant to scratches, chips, and cracks. They can last for many years with proper care.</p>
                      <p>3) Water-resistant: Ceramic wall tiles are not waterproof, but they are very water-resistant. This makes them a good choice for wet areas like bathrooms and kitchens.</p>
                      <p>4) Easy to clean: Ceramic wall tiles are easy to clean and maintain. They can be wiped down with a damp cloth or sponge.</p>

                    </div>
                  </div>
                </div>

                {/* 12 */}
                <div className="row mpt-50 spb-80 new-display-other" id="matt_tiles">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>MATT TILES</h2>
                      <h6>MATT Durable Porcelain Floor Tiles</h6>
                      <h6>Matte tiles, also known as matt tiles, are a popular choice for both wall and floor applications due to their unique characteristics.</h6>

                      <p>1) Material Composition: Matt tiles are commonly made from materials such as porcelain, ceramic, or natural stone. These materials contribute to the durability and longevity of the tiles, ensuring they can withstand daily wear and tear in high-traffic areas.</p>
                      <p>2) Suitable for : They are also suitable for creating monochrome interiors and can be paired with</p>
                      <p>3) unglazed floor tiles to create a unified aesthetic for walls and floors
                        Professional Expertise: For complex installations or large projects, consulting a professional tiler ensures proper levelling, grouting, and adherence to industry standards.</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/MATT.jpg" alt="MATT" />
                    </div>
                  </div>
                </div>

                {/* 13 */}
                <div className="row mpt-50 spb-80 new-display-other" id="book-new">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/BOOK-MATCH.jpg" alt="BOOK-MATCH" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>BOOK MATCH TILES</h2>
                      <h6>BOOKMATCH Tiles: The Art of Natural Stone Harmony</h6>
                      <h6>Book match tiles are a remarkable innovation in the world of interior design, offering an unparalleled level of elegance and sophistication. Derived from the centuries-old technique of book matching natural stone slabs.</h6>

                      <p>1) Mirrored Patterns: Book match tiles are created by slicing natural stone or porcelain slabs into thin sections and then arranging them in sequential order like the pages of a book.</p>
                      <p>2) Enhanced aesthetics: Create a luxurious and unified look, elevating the design of any space.</p>
                      <p>3) Durability: Many stones offer excellent durability, lasting for generations with proper care.</p>
                    </div>
                  </div>
                </div>

                {/* 14 */}
                <div className="row mpt-50 spb-80 new-display-other" id="glossy_tiles">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>GLOSSY TILES</h2>
                      <h6>Glossy Tiles: The Uncompromising Blend of Beauty and Ease</h6>
                      <h6>Glossy tiles are known for their highly reflective finish, which can make a space feel brighter and more spacious.</h6>

                      <p>1)  Surface Finish: Glossy tiles feature a smooth, polished surface that reflects light, creating a luminous effect that enhances the brightness and spaciousness of a room. </p>
                      <p>2) Material Composition: Glossy tiles can be crafted from various materials, including ceramic, porcelain, glass, and even natural stone.</p>
                      <p>3) Maintenance: Regular cleaning with mild detergents is crucial to maintain their shine and prevent watermarks.</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/GLOSSY.jpg" alt="GLOSSY" />
                    </div>
                  </div>
                </div>

                {/* 15 */}
                <div className="row mpt-50 spb-80 new-display-other" id="high-glosaay">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/HIGH-GLOSSY.jpg" alt="HIGH-GLOSSY" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>HIGH GLOSSY TILES.</h2>
                      <h6>High Glossy Tiles: Shiny Surfaces for Stunning Spaces</h6>
                      <h6>High-glossy tiles elevate the world of glossy tiles to a whole new level. Boasting an incredibly reflective surface, they create a mirror-like finish that adds an undeniable wow factor to any space.</h6>

                      <p>1) Material Mastery: Primarily crafted from high-quality ceramic or porcelain, high-glossy tiles offer exceptional durability and resistance to moisture, making them suitable for various applications.</p>
                      <p>2) Ultra-Reflective Surface: High glossy tiles feature a polished surface finish that reflects light with exceptional clarity and intensity.</p>
                      <p>3) Easy Maintenance: Despite their glossy appearance, high glossy tiles are surprisingly easy to clean and maintain.</p>
                    </div>
                  </div>
                </div>

                {/* 16 */}
                <div className="row mpt-50 spb-80 new-display-other" id="carving-tiles">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>CARVING TILES</h2>
                      <h6>Unveiling the Artistry of Carved Tiles: A Detailed Exploration</h6>
                      <h6>Carving tiles are a type of decorative tile that features intricate designs and patterns carved into the surface of the tile.</h6>

                      <p>1) Feature Walls: Carved tiles make stunning feature walls that serve as focal points in living rooms, dining areas, or entryways.</p>
                      <p>2) Material Options: Primarily crafted from ceramic or porcelain, carving tiles can also be found in natural stones like sandstone or limestone, offering different textures and finishes.</p>
                      <p>3) Kitchen Backsplashes: In kitchens, carved tiles can elevate the design of backsplashes, adding a touch of sophistication and style.</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/CARVING.jpg" alt="CARVING" />
                    </div>
                  </div>
                </div>

                {/* 17 */}
                <div className="row mpt-50 spb-80 new-display-other" id="wood_tiles">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/WOODTILE.jpg" alt="WOODTILE" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>WOOD TILES</h2>
                      <h6>Warmth for Your Walls: Unveiling the Beauty of Wood Tiles</h6>
                      <h6>Wood tiles, also known as wood-look tiles or porcelain wood tiles, are a versatile and innovative alternative to traditional hardwood flooring. Combining the timeless elegance of wood with the durability and practicality of ceramic or porcelain tiles, wood tiles offer a wide range of benefits for both residential and commercial spaces.</h6>

                      <p>1) Ceramic and porcelain: Popular choices for their durability, water resistance, and wide range of styles and finishes.</p>
                      <p>2) Luxury vinyl tile (LVT): Offers incredible realism, flexibility, and cushion underfoot, but might be less durable than ceramic or porcelain.</p>
                      <p>3) Engineered wood tiles: Made from real wood with a protective layer, offering a truly authentic texture and warmth, but requiring specific care and installation.</p>
                    </div>
                  </div>
                </div>

                {/* 18 */}
                <div className="row mpt-50 spb-80 new-display-other" id="press">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="text_div">
                      <h2>PRESS VITRIFIED TILES</h2>
                      <h6>Press Vitrified Tiles: Experience the Luxury of Low Maintenance</h6>
                      <h6>Press vitrified tiles (PVT) are a type of ceramic tile made by applying high pressure to a mixture of clay, quartz, feldspar, and silica. This process creates a dense, non-porous tile that is highly resistant to wear, tear, and moisture. Press vitrified tiles are often used in high-traffic areas such as commercial buildings, airports, and hospitals.</h6>

                      <p>1) Durability: Press vitrified tiles are renowned for their exceptional durability, making them ideal for high-traffic areas such as hallways, kitchens, and commercial spaces.</p>
                      <p>2) Walls: Press vitrified tiles can also be used on walls. They are a good choice for areas that are exposed to moisture, such as showers and backsplashes.</p>
                      <p>3) Easy to clean: Press vitrified tiles are easy to clean with a damp mop and mild detergent.</p>
                      <p>4) Cost-Effective: Vitrified tiles are cost-effective and come in a variety of designs, making them a popular choice for residential and commercial spaces</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/PRESS-VITRIFIED-TILES.jpg" alt="PRESS-VITRIFIED-TILES" />
                    </div>
                  </div>
                </div>

                {/* 19 */}
                <div className="row mpt-50 spb-80 new-display-other" id="subway_tiles">
                  <div className="col-lg-6 col-md-6 cols-12">
                    <div className="img_div">
                    <img src="/preview/SUBWAY-TILES.jpg" alt="SUBWAY-TILES" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 cols-12" >
                    <div className="text_div">
                      <h2>SUBWAY TILES</h2>
                      <h6>Subway Tiles: Timeless Elegance for Modern Spaces</h6>
                      <h6>Subway tiles are a popular choice for various applications in residential and commercial settings. In kitchens, they are commonly used as backsplashes to protect walls from splashes and spills while adding a touch of style</h6>

                      <p>1) Material: Made from durable clay, quartz, and feldspar, fired at high temperatures for exceptional strength and density.</p>
                      <p>2) Versatility in Design: Subway tiles offer versatility in design, as they can be arranged in various patterns, including the classic brickwork pattern, stacked, straight, herringbone, or chevron.</p>
                      <p>3) Historical Significance: Subway tiles have a storied 100-year history and are known for their enduring style and versatility in design</p>
                      <p>4) Outdoor spaces: Weather-resistant options enhance patios, balconies, and pool areas with a touch of urban chic.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2 padding-top">
              <h2 className="h2_heading text-center mb-5">
                DOWNLOAD LATEST CATALOGUE
              </h2>
              <div className="container">
                <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3 active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#mosaic" type="button" role="tab" aria-controls="mosaic" aria-selected="true">Mosaic</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#porcelain-tiles" type="button" role="tab" aria-controls="porcelain-tiles" aria-selected="false">Porcelain Tiles</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3" id="pills-third-tab" data-bs-toggle="pill" data-bs-target="#porcelain-slab" type="button" role="tab" aria-controls="porcelain-slab" aria-selected="false">Porcelain Slab</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3" id="pills-four-tab" data-bs-toggle="pill" data-bs-target="#outdoor" type="button" role="tab" aria-controls="outdoor" aria-selected="false">Outdoor</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link px-lg-5 py-md-2 px-3" id="pills-five-tab" data-bs-toggle="pill" data-bs-target="#sintered-stone" type="button" role="tab" aria-controls="sintered-stone" aria-selected="false">Sintered Stone</button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {/* ======================================================================================================================================================================================================== */}

                  {/* MOSAIC */}

                  {/* ====================================================================================================================================================================== */}

                  <div className="tab-pane fade show active" id="mosaic" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">

                      {
                        mosaic.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="product" />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>
                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#mosaicModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>

                                    {/* Modal  */}
                                    <div className="modal fade" id="mosaicModal" tabindex="-1" aria-labelledby="mosaicModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="mosaicModalLabel">Fill This Form   </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                  <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">

                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""
                                                        value={productName}
                                                        readOnly />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name} />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address}
                                                  />
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required
                                                  />
                                                </div>
                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                  {/* ======================================================================================================================================================================================================== */}

                  {/* PORCELAIN TILES */}

                  {/* ====================================================================================================================================================================== */}
                  <div className="tab-pane fade" id="porcelain-tiles" role="tabpanel" aria-labelledby="pills-second-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">

                      {
                        porcelainTiles.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="procelain-tiles" />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>
                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#downloadModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>
                                    {/* Modal  */}
                                    <div className="modal fade" id="downloadModal" tabindex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="downloadModalLabel">Fill This Form </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                  <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""
                                                        value={productName}
                                                        readOnly
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address}
                                                  />
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required
                                                  />
                                                </div>
                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                  {/* ======================================================================================================================================================================================================== */}

                  {/* PORCELAIN SLAB */}

                  {/* ====================================================================================================================================================================== */}

                  <div className="tab-pane fade" id="porcelain-slab" role="tabpanel" aria-labelledby="pills-third-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">
                      {
                        porcelainSlad.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="porcelainSlad" style={{ backgroundColor: '#000' }} />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>
                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#porcelainSladModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>
                                    {/* Modal  */}
                                    <div className="modal fade" id="porcelainSladModal" tabindex="-1" aria-labelledby="porcelainSladModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="porcelainSladModalLabel">Fill This Form    </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                  <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""
                                                        value={productName}
                                                        readOnly
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address} />
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required />
                                                </div>
                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                  {/* ======================================================================================================================================================================================================== */}

                  {/* OUTDOOR */}

                  {/* ====================================================================================================================================================================== */}

                  <div className="tab-pane fade" id="outdoor" role="tabpanel" aria-labelledby="pills-four-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">
                      {
                        outdoor.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="otdoor-map" />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>
                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#outdoorModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>
                                    {/* Modal  */}
                                    <div className="modal fade" id="outdoorModal" tabindex="-1" aria-labelledby="outdoorModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="outdoorModalLabel">Fill This Form  </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                  <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">

                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""

                                                        value={productName}
                                                        readOnly
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name} />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address}
                                                  />
                                                </div>

                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required
                                                  />
                                                </div>
                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        })
                      }
                    </div>
                  </div>
                  {/* ======================================================================================================================================================================================================== */}

                  {/* SINTERED STONE */}

                  {/* ====================================================================================================================================================================== */}

                  <div className="tab-pane fade" id="sintered-stone" role="tabpanel" aria-labelledby="pills-five-tab" tabIndex="0">
                    <div className="row d-flex justify-content-center">
                      {
                        sintered.map((product) => {
                          return (
                            <>
                              <div className="col-lg-4 col-md-6 mb-5">
                                <div className="wrapper">
                                  <div className="image-wrapper">
                                    <img src={product.url} alt="sintered" />
                                  </div>
                                  <div className="text-center">
                                    <h6>{product.name}</h6>
                                    {/* Button trigger modal  */}
                                    <button className="download-btn" data-bs-toggle="modal" data-bs-target="#sinteredModal" onClick={() => handleButtonClick(product)} >
                                      Download
                                    </button>
                                    {/* Modal  */}
                                    <div className="modal fade" id="sinteredModal" tabindex="-1" aria-labelledby="sinteredModalLabel" aria-hidden="true">
                                      <div className="modal-dialog modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="sinteredModalLabel">Fill This Form </h1>
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                                          </div>
                                          <div className="modal-body">
                                            <div className="container">
                                              <form onSubmit={(event) => onSubmit(event, { pdf: product.pdf })} >
                                                <div className="row">
                                                  <div className="col-md-12 mb-4">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Product Detail</label>
                                                      <input
                                                        className="form-control"
                                                        name="product_name"
                                                        type="text"
                                                        placeholder=""
                                                        value={productName}
                                                        readOnly
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start">
                                                      <label className="form-label">Your Name*</label>
                                                      <input
                                                        className="form-control"
                                                        name="your_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.your_name}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start ">
                                                      <label className="form-label">Company Name</label>
                                                      <input
                                                        className="form-control"
                                                        name="comp_name"
                                                        type="text"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.comp_name}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start mt-4">
                                                  <label className="form-label">Address</label>
                                                  <input
                                                    className="form-control"
                                                    name="address"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.address}
                                                  />
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">City*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="city"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.city}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">State*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="state"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.state}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Country*</label>
                                                      <input
                                                        className="form-control"
                                                        type="text"
                                                        name="country"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.country}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="form-group text-start mt-4">
                                                      <label className="form-label">Email id*</label>
                                                      <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        placeholder=""
                                                        onChange={handleChange}
                                                        value={formData.email}
                                                        required
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start my-4">
                                                  <label className="form-label">Contact No.*</label>
                                                  <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    value={formData.contact}
                                                    required
                                                  />
                                                </div>
                                                <div className="text-center">
                                                  <button type="submit" className="mt-4 form-button">Send</button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )

                        })
                      }
                    </div>
                  </div>
                </div>
                <p className="fs-5 fw-normal mt-5">
                  <b>Ignite<sup>&#174;</sup> </b> Tiles Are Super Drable And Long-Lasting,
                  Creating Attractiveness & Aesthetic Appeal in Your Home For
                  Years. They Are Available In A Vast Variety Of Colours,
                  Shapes, Textures And Sizes, Easily Mimicking Other Natural
                  Materials While Maintaining Easy-To-Clean Properties.
                </p>
                <p className="fs-5 fw-normal">
                  Constant Innovation And Leading Technology Show Our Product
                  And Approach As A Leading Tile Manufacturer And Exporter In
                  India. Check Out Fabulous Designs From Our Tile Collections,
                  Adding Value To Your Luxurious Lifestyle.
                </p>
              </div>
            </section>

            <Latest_Insights />
            <div class="row">
              <div class="column">
              </div>
            </div>
          </main>

          <Footer />
        </>
      )}
    </>
  )
} 
