import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

export default function Loading() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Loading...</title>
        <meta
          name="description"
          content="Ignite is renowned for manufacturing and exporting a varied range of tiles with exceptional durability, innovative designs and aesthetic appeal."
        />
      </Helmet>
      <div id="preloader">
        <img src="images/nav-logo.png" alt="nav-logo" height="80px" width="auto" />
      </div>
    </>
  );
}
