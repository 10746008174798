import { Link } from "react-router-dom";

export default function PageNotFound(){
    return(
        <>
            <div id="page-not-found">
                <h1>Oops!</h1>
                <p className="my-4">Page Not Found</p>
               <Link to="/"><button>Go To Home</button></Link>
            </div>
        </>
    )
}