import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';  
import Navbar from './components/navbar';
import Home from './components/home';
import Company from './components/company';
import Contact from './components/contact';
import Media from './components/media';
import Products from './components/products';
import Footer from './components/footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Blogs from './components/blogs';
import Blog_Inside from './components/blog-inside';
import ScrollToTop from './components/scroll-to-top';
import Everything_You_Need_To_Know from './components/everything-you-need-to-know';
import Reason_To_Choose_Ceramic_Tiles_For_Your_Home from './components/reason-to-choose-porcelain-tiles-for-your-home';
import Faq from './components/faq';
import PageNotFound from './components/NotFoundPage';
import Download from './components/download'
import ThankYou from './components/thank-you-page';
import Gallery from './components/Gallery';

ReactDOM.render(
  <React.StrictMode>
    {/* <Gallery /> */}
  </React.StrictMode>,
  document.getElementById('root')
);

function App() {

  return (
    <>
      <Router>
        {/* <Navbar /> */}
        <ScrollToTop />
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/company' element={<Company />}></Route>
          <Route exact path='/contact' element={<Contact />}></Route>
          <Route exact path='/media' element={<Media />}></Route>
          <Route exact path='/blogs' element={<Blogs />}></Route>
          <Route exact path='/products' element={<Products />}></Route>
          {/* <Route exact path='/blog-inside' element={<Blog_Inside />}></Route> */}
          <Route exact path='/mosaic-tiles-a-complete-guide' element={<Everything_You_Need_To_Know />}></Route>
          <Route exact path='/reason-to-choose-porcelain-tiles-for-your-home' element={<Reason_To_Choose_Ceramic_Tiles_For_Your_Home />}></Route>
          <Route exact path='/faqs' element={<Faq />}></Route>
          <Route exact path='/download' element={<Download />}></Route>
          <Route exact path='/thankyou' element={<ThankYou />}></Route>
          <Route exact path='*' element={<PageNotFound />} />
          {/* <Redirect to="/404"></Redirect> */}
        </Routes>
      {/* <Footer /> */}
      </Router>
      {/* <Home /> */}
    </>
  );
  
}

export default App;
