import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";


// import required modules
import { Autoplay } from "swiper";

export default function Slider() {
    return (
        <>
            <section className="slider container padding-top">
                <Swiper
                    spaceBetween={30}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        576: {
                         
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 2,
                        },
                        1020: {
                          slidesPerView: 3,
                        },
                        1200: {
                          slidesPerView: 5,
                        },
                      }}
                    modules={[Autoplay]}
                    className="mySwiper">
                </Swiper>
            </section>
        </>
    )
}