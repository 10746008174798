import React from "react";
import {
   FaFacebookF,
   FaInstagram,
   FaTwitter,
   FaLinkedinIn,
   FaYoutube,
   FaPinterestP,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
   return (
      <footer className="padding-top">
         <div className="footer-logo">
            <a href="https://ceramiczone.co.in/">
               <img src="/images/footer-logo.png" alt="footer-logo" className="logo-image" />
            </a>
         </div>
         <div className="bg-color">
            <div className="container">
               <div className="row mt-5 gx-5">
                  <div className="col-lg-3">
                     <p className="" style={{ fontSize: "16px" }}>
                        Ignite is a tile brand in India offering all types of
                        wall and floor tiles for residential, commercial and industrial
                        applications. From  <b>
                           outdoor, porcelain tiles, porcelain slab, mosaic, sintered
                           stone and ceramic floor & wall tiles
                        </b>, and subway tiles to swimming pool tiles, mosaic tiles,
                        GVT & PGVT tiles, full-body unglazed porcelain tiles, we offer a wide range of products that suit your tiling
                        needs.
                     </p>
                  </div>

                  <div className="col-lg-2">
                     <h4>Links</h4>
                     <p>
                        <Link to="/company">Company</Link> <br /> {" "}
                        <Link to="/products">Products</Link> <br /> {" "}
                        <Link to="/media">Media</Link> <br />
                        <Link to="/blogs">Blogs</Link> <br /> {" "}
                        <Link to="/contact">Contact</Link> <br />
                     </p>

                  </div>

                  <div className="col-lg-2">
                     <h4>Quick Products</h4>
                     {/* <p> */}
                     {/* <Link to="/products">
                           <ul className="notfound-name">
                              <li><a href="#">Mosaic</a></li>
                              <li><a href="#">Outdoor</a></li>
                              <li><a href="#">Porcelain Tiles</a></li>
                              <li><a href="#">Porcelain Slab</a></li>
                              <li><a href="#">intered Stone</a></li>
                           </ul>
                        </Link> */}
                     <Link to="/products">
                        <ul className="notfound-name">
                           <li><a href="#">MOSAIC TILES</a></li>
                           <li><a href="#">GVT/GPVT</a></li>
                           <li><a href="#">SINTERED STONE</a></li>
                           <li><a href="#">SLAB TILES</a></li>
                           <li><a href="#">FULL BODY</a></li>
                           <li><a href="#">CERAMIC FLOOR TILES</a></li>
                           <li><a href="#">CERAMIC WALL TILES</a></li>
                        </ul>
                     </Link>
                     {/* </p> */}
                  </div>

                  <div className="col-lg-3">
                     <h4>Head Office</h4>
                     <address>
                        1st Floor, Ceramic Zone Complex, Nr Metro Ceramic, Lakhdhirpur
                        Road, Morbi (guj.) India - 363 642
                     </address>
                     <h4>Factory Unit 1</h4>
                     <address>
                        Street Of Ambe Way Bridge, Lakhdhirpur Road, Morbi (Guj.) India -
                        363 642
                     </address>
                     <h4>Factory Unit 2</h4>
                     <address>
                        Behind Ceramic Zone Complex, Lakhdhirpur Road, Morbi (guj.)
                        India - 363 642
                     </address>
                  </div>
                  <div className="col-lg-2">
                     <div className="email_div_footer">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                        <div className="email-links">
                           <a href="#">export@ceramiczone.co.in</a> <br />
                           <a href="#">sales@ignitemosaic.com</a>
                        </div>
                     </div>

                     <div className="contact-div">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                        <div className="contact-now">
                           <a href="#">+91 90331 98246</a> <br />
                           <a href="#">+91 99786 24022</a>
                        </div>
                     </div>

                     <div className="social-icons">
                        <span className="icon-bg ms-0">
                           <Link to="https://www.facebook.com/ignitemosaic/"> <FaFacebookF className="icon" /> </Link>
                        </span>
                        <span className="icon-bg">
                           <Link to='https://www.instagram.com/ignitemosaic/'> <FaInstagram className="icon" /> </Link>
                        </span>
                        <span className="icon-bg">
                           <Link to='https://twitter.com/IgniteMosaic'><FaTwitter className="icon" /></Link>
                        </span>
                        <span className="icon-bg">
                           <Link to='https://youtube.com/@ignitemosaic?si=fog4YVrAQNO5QV2r/'> <FaYoutube className="icon" /> </Link>
                        </span>
                        <span className="icon-bg">
                           <Link to='https://www.pinterest.co.kr/ceramiczone/'> <FaPinterestP className="icon" /> </Link>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
            <hr />
            <div className="container">
               <div className="row">

                  <div className="col-lg-6 d-flex">
                     {/* <div className="social-icons">
                        <span className="icon-bg ms-0">
                           <Link to="https://www.facebook.com/ignitemosaic/"> <FaFacebookF className="icon" /> </Link>
                        </span>
                        <span className="icon-bg">
                           <Link to='https://www.instagram.com/ignitemosaic/'> <FaInstagram className="icon" /> </Link>
                        </span>
                        <span className="icon-bg">
                           <Link to='https://twitter.com/IgniteMosaic'><FaTwitter className="icon" /></Link>
                        </span>
                        <span className="icon-bg">
                           <Link to='https://youtube.com/@ignitemosaic?si=fog4YVrAQNO5QV2r/'> <FaYoutube className="icon" /> </Link>
                        </span>
                        <span className="icon-bg">
                           <Link to='https://www.pinterest.co.kr/ceramiczone/'> <FaPinterestP className="icon" /> </Link>
                        </span>
                     </div> */}
                     <div className="copy_right_div">
                        <p>Copyright © 2024 <a href="https://ceramiczone.co.in/"> Ignitemosaic</a> | Developed by <a href="https://leafwayinfotech.com/">  leafwayinfotech</a></p>
                     </div>
                  </div>

                  <div className="col-lg-6 d-flex justify-content-end ">
                     <p><a href="#">Terms & conditions</a></p>
                     <p className="ms-3"><a href="#">Privacy Policy</a></p>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   );
};

export default Footer;
