import React from "react";

export default function Banner(props){
    const url = props.image
    return (
        <section className="banner-section">
            <div className="bg">
                <img src={url} />
                <div className="text-wrapper">
                    <h1>Contact Us</h1>
                    <h4>les't build someting great together</h4>
                </div>
                <div className="bg-color"></div>
            </div>
        </section>
    )
}