import { Link } from "react-router-dom";

export default function About_Mosaic() {
  return (
    <section className="about-mosaic padding-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="text-wrapper">
              <h3 className="h2_heading">ABOUT IGNITE<sup>&#174;</sup></h3>
              <p className="mt-4">
                Introducing Ignite, an innovative tile brand in India,
                offering a vast expanse of tiles collection for every place. We
                offer excellent quality vitrified mosaic tiles, inlay mosaic
                tiles, swimming pool mosaics in crystal glass, hot melting
                glass, and ceramic mosaic & also we provide{" "}
                <b>
                  Outdoor, Porcelain Tiles, Porcelain Slab, Mosaic, Sintered
                  Stone And Ceramic Floor & Wall Tiles.
                </b>
              </p>
              <p>
                Our well-curated collection comes in many different sizes. If you are looking for
                customised tile sizes for your architectural requirements,
                Ignite has a solution for that too! After all, our client is our
                king!
              </p>
              <p>
                Looking to kindle your fireplace with mosaic tiles on the wall?
                What about a uniquely tiled swimming pool to have fun? Or want
                to lend a charming appeal to the outdoor patio? Whatever your
                wall and floor tiles need, we are here to meet your needs.
              </p>
              <p>
                Adding the perfect balance of aesthetics and utility to your
                home is easy with our wall and floor application collections!
              </p>
              <Link to="/company">
                <p>About Ignite</p>
              </Link>
            </div>
          </div>
          <div className="col-lg-6">
            <img src="/preview/orrara_grey_800x2400_15_ss.jpg" alt="orrangepreview" />
          </div>
        </div>
      </div>
    </section>
  );
}