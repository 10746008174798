import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaTwitter,
  FaInstagram,
  FaFacebookF,
} from "react-icons/fa";
import Social_Icons from "./social-icons";
import { useNavigate } from "react-router-dom";

const Navbar = ({ modalBg }) => {
  const [modalClose, setModalClose] = useState(false);
  const [emailData, setEmailData] = useState({
    your_name: '',
    email: '',
    message: ''
  });
  const headerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScrollToSection = (id) => {
      const headerHeight = headerRef.current.offsetHeight;
      const section = document.getElementById(id);
      if (section) {
        const sectionPosition = section.offsetTop - headerHeight - 85;
        window.scrollTo({
          top: sectionPosition,
          behavior: 'smooth'
        });
      }
    };

    const handleNavbarClick = (event) => {
      if (event.target.tagName === 'A' && event.target.getAttribute('href').startsWith('#')) {
        event.preventDefault();
        const id = event.target.getAttribute('href').substring(1);
        handleScrollToSection(id);
      }
    };

    document.addEventListener('click', handleNavbarClick);

    return () => {
      document.removeEventListener('click', handleNavbarClick);
    };
  }, []);


  const onSubmit = e => {
    e.preventDefault();
    var form = document.getElementById("myForm");
    if (form.checkValidity()) {
      setModalClose(true)

      fetch("http://localhost:3000/send", {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: emailData.your_name,
          email: emailData.email,
          mobile: emailData.number,
          message: emailData.message
        })
      }).then(res => res.json())
        .then(data => {

          navigate('/thankyou');
        }).catch(err => {
          console.log(err)
        });

      console.log("valid");
    } else {
      console.log("Fill Form")
    }

  }

  const handleChange = (e) => {
    setEmailData({
      ...emailData,
      [e.target.name]: e.target.value
    });
  };


  return (
    <>
      <header id="header" ref={headerRef}>
        <head>
        <meta name="google-site-verification" content="ibVCUh5EdvAXz62uSCKt5h9icq0GzLvqOktig8eAW9s" />

        </head>
        
        <nav className="navbar navbar-expand-lg nav-bg fixed-top navbar-scroll nav-fixed">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src="/images/footer-logo.png" alt="logo-image" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNavAltMarkup"
            >
              <div className="navbar-nav">
                <Link to="/company" className="nav-link">
                  Company
                </Link>

                <ul>
                  <li><a href="#" className="new-one-some">
                    <Link to="/products" className="nav-link">
                      Products</Link></a>
                    <ul>
                      <li><a href="#">MOSAIC TILES</a>
                        <ul>
                          <li><a href="#vitrified">VITRIFIED MOSAIC TILES</a></li>
                          <li><a href="#press">PRESS VITRIFIED TILES</a></li>
                          <li><a href="#swimming">SWIMMING POOL TILES</a></li>
                          <li><a href="#book-new">BOOK MATCH</a></li>
                          <li><a href="#subway_tiles">SUBWAY TILES</a></li>
                        </ul>
                      </li>

                      <li><a href="#">GVT/GPVT</a>
                        <ul>
                          <li><a href="#">600X600MM</a>
                            <ul>
                              <li><a href="#glossy_tiles">GLOSSY</a></li>
                              <li><a href="#matt_tiles">MATT</a></li>
                              <li><a href="#book-new">BOOK MATCH</a></li>
                            </ul>
                          </li>

                          <li><a href="#">600X1200MM</a>
                            <ul>
                              <li><a href="#glossy_tiles">GLOSSY</a></li>
                              <li><a href="#high-glosaay">HIGH GLOSSY</a></li>
                              <li><a href="#carving-tiles">CARVING</a></li>
                              <li><a href="#book-new">BOOK MATCH</a></li>
                              <li><a href="#wood_tiles">WOOD</a></li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                      <li><a href="#">SINTERED STONE</a>
                        <ul>
                          <li><a href="#purv_series">PURV SERIES</a></li>
                          <li><a href="#ultima_series">ULTIMA SERIES</a></li>
                        </ul>
                      </li>

                      <li><a href="#">SLAB TILES</a>
                        <ul>
                          <li><a href="#hastag_series">HASHTAG SERIES</a></li>
                          <li><a href="#somu_series">SOMU SERIES</a></li>
                        </ul>
                      </li>

                      <li><a href="#">FULL BODY</a>
                        <ul>
                          <li><a href="#roman-tiles">ROMAN TILES</a></li>
                          <li><a href="#rare-uniqe">RARE & UNIQUE TILES</a></li>
                        </ul>
                      </li>
                      <li><a href="#ceramic-floor">CERAMIC FLOOR TILES</a></li>
                      <li><a href="#ceramic-wall">CERAMIC WALL TILES</a></li>
                    </ul>
                  </li>
                </ul>
                <Link to="/media" className="nav-link">
                  Media
                </Link>
                <Link to="/blogs" className="nav-link">
                  Blogs
                </Link>
                <Link to="/faqs" className="nav-link">
                  Faqs
                </Link>
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
                <Link to="#" className="nav-link">
                  <button
                    className="nav-button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    Get a Quote
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </nav>
        <div
          className={`modal fade thankyou-modal modal-bg ${modalBg}`}
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel">
          <div className="modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered ">
            <div className="modal-content pt-3 pb-5 px-md-5 px-2">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                  aria-label="Close">
                </button>
              </div>
              <div className="modal-body">
                <div className="bg-color">
                  <div className="row d-flex justify-content-center gx-5">
                    <div className="col-xl-5 col-lg-6">
                      <h3 className="font-size">Get a Quote</h3>
                      <p>
                        <b>Ignite<sup>&#174;</sup> </b> is a tile brand in India offering all
                        types of wall and floor tiles for residential,
                        commercial and industrial applications.
                      </p>
                      <div className="wrapper">
                        <p>
                          {" "}
                          <FaPhoneAlt className="me-3 icon" />
                          +91 90331 98246 / +91 99786 24022
                        </p>
                        <p className="py-4">
                          <FaEnvelope className="me-3 icon" />
                          export@ceramiczone.co.in / sales@ignitemosaic.com
                        </p>
                        <p>
                          <FaMapMarkerAlt className="me-3 icon" />
                          1st Floor, Ceramic Zone Complex, Nr Metro Ceramic,
                          Lakhdhirpur Road, Morbi (guj.) India - 363 642
                        </p>
                      </div>
                      <div className="d-flex ">
                        <div className="icon-bg">
                          <Link to="https://www.facebook.com/ignitemosaic/"> <FaFacebookF className="social-icon" /></Link>
                        </div>
                        <div className="icon-bg">
                          <Link to='https://www.instagram.com/ignitemosaic/'> <FaInstagram className="social-icon" /> </Link>
                        </div>
                        <div className="icon-bg">
                          <Link to='https://twitter.com/IgniteMosaic'><FaTwitter className="social-icon" /></Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 mt-lg-0 mt-4">
                      <div className="form-bg">
                        <form onSubmit={onSubmit} id="myForm">
                          <div className="form-group">
                            <label className="form-label">Your Name</label>
                            <input
                              className="form-control"
                              name="your_name"
                              type="text"
                              placeholder=""
                              onChange={handleChange}
                              value={emailData.your_name}
                              required
                            />
                          </div>
                          <div className="form-group my-4">
                            <label className="form-label">Mail</label>
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              placeholder=""
                              onChange={handleChange}
                              value={emailData.email}
                              required
                            />
                          </div>
                          <div className="form-group my-4">
                            <label className="form-label">Contact</label>
                            <input
                              className="form-control"
                              name="number"
                              type="tel"
                              id="floatingInput"
                              onChange={handleChange} value={emailData.number}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Message</label>
                            <textarea
                              name="message"
                              id=""
                              cols="30"
                              rows="5"
                              className="form-control"
                              onChange={handleChange}
                              value={emailData.message}
                            ></textarea>
                          </div>
                          <div className="text-end">
                            <button type="submit" data-bs-dismiss={`${modalClose && 'modal'}`} aria-label={`${modalClose && 'Close'}`} className=" mt-4 form-button">Send</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >
      <Social_Icons />
    </>
  );
};
export default Navbar;