import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import Loading from "./loading";
import Navbar from "./navbar";
import Footer from "./footer";
import $ from 'jquery';

export default function ThankYou() {
    const [loading, setLoading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Products</title>
                <meta
                    name="description"
                    content="Ignite is renowned for manufacturing and exporting a varied range of tiles with exceptional durability, innovative designs and aesthetic appeal."
                />
            </Helmet>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Navbar />
                    <div id="page-not-found">
                        <h3>Thank You For Submitting Form!</h3>
                        <p className="my-4">We Will Contact You Shortly......</p>
                        <Link to="/"><button>Go To Home</button></Link>
                    </div>
                    <Footer />
                </>
            )}
        </>
    )
}